<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#e6e6f1" class="lot_login">
      <v-overlay
        :absolute="absolute"
        :opacity="0.3"
        :value="overlay"
      >
        <v-card flat width="480" class="login-wrap">
          <v-card-title class="pa-0 text-center">
            <h1>
              블록체인 기반<br>
              <strong>사내 추첨 시스템</strong>
            </h1>
            <div class="pt-4 d-block h-desc black--text">
              전자지갑 정보를 확인 중입니다
            </div>
          </v-card-title>
          <br/>
          <v-card-text class="pa-0">
            <v-container class="pa-0 align-content-center align-center">
              <v-row class="mt-0 justify-center">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-overlay>
      <div class="bg-area">
        <div class="symbol_01">
          <v-img src="@/assets/images/ess_bg01.svg" alt=""></v-img>
        </div>
        <div class="symbol_02">
          <v-img src="@/assets/images/ess_bg02.svg" alt=""></v-img>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { api } from '@/axios';
import storage from '../common/storage';

export default {
  name: 'SloAuth',
  components: {},
  data() {
    return {
      loading: false,
      absolute: true,
      overlay: true,
      editedItem: {},
      tabBoolean: true,
    };
  },
  created() {
    this.slo_p_ota = this.$route.query.slo_p_ota;
    this.login();
  },
  methods: {
    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    login() {
      api.post('/auth/slo', { slo_p_ota: this.slo_p_ota }).then((res) => {
        if (res.status === 200) {
          this.token = res.data.token;
          this.refreshToken = res.data.refreshToken;
          storage.setToken(res.data);
          storage.setRefreshToken(res.data);
          localStorage.setItem('userId', jwtDecode(this.token).uid);
          localStorage.setItem('role', jwtDecode(this.token).auth);
          localStorage.setItem('userNm', jwtDecode(this.token).nm);
          // localStorage.setItem('job', jwtDecode(this.token).jb);
          // localStorage.setItem('deptNm', jwtDecode(this.token).dn);
          console.log(jwtDecode(this.token));
          this.$EventBus.$emit('userId', jwtDecode(this.token).uid);
          this.$EventBus.$emit('empNm', jwtDecode(this.token).nm);
          this.tabBoolean = !this.tabBoolean;
          this.overlay = false;
          this.$router.push({ name: 'main', params: { eventId: this.$route.query.eventId } });
        }
      });
    },
    submit() {
      console.log('submit');
    },
    openModal(name, item) {
      const userid = localStorage.getItem('userId');
      if (userid === null || userid === undefined || userid === '') {
        alert('사용자 정보가 없습니다, 클레버스 로그인 후 이용하세요');
        return;
      }
      console.log('name:', name, item);
      this.$data[name] = true;
      this.loading = true;

      localStorage.setItem('tab', '0');
      if (item !== undefined && item !== 'winnerList') {
        const obj = {};
        this.editedItem = Object.assign(obj, item);
        this.editedItem = item;
      } else if (item === 'winnerList') {
        this.tabBoolean = !this.tabBoolean;
        localStorage.setItem('tab', '2');
      }

      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.loading = false), 1500);
    },
  },
};
</script>
